<template>
  <div class="my-point-wrapper">
    <div class="content-wrapper" ref="content">
      <div class="point-header-wrapper">
        <div class="title-wrapper">
          <h4 class="title">当前积分</h4>
          <router-link to="/myPoint/leaderBoard" tag="div" class="leaderboard">
            <i class="iconfont iconpaihangbang"></i>
            <p>排行榜</p>
          </router-link>
        </div>
        <p class="point">{{point}}</p>
        <p class="deadline" v-if="isShowDeadLine">积分有效期：{{pointDeadLine}}</p>
      </div>
      <van-sticky :offset-top="-10" @scroll="handleScroll">
        <van-tabs
          ref="tabs"
          class="tab-list"
          v-model="activeTab"
          color="#117BEE"
          title-active-color="#117BEE"
          title-inactive-color="#333"
          @change="handleTabChange">
          <van-tab v-for="tab in tabs" :name="tab.key" :title="tab.name" :key="tab.key">
          </van-tab>
        </van-tabs>
      </van-sticky>
      <div class="card-box tab-content-wrapper">
        <h4 class="title">{{activeTabTitle}}</h4>
        <div class="tab-content">
          <component :is="activeTab" @exchangeSuccess="getPersonCredit"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/index'
const { getPersonCredit, getCreditDisplayClear } = API
const currentYear = new Date().getFullYear()

export default {
  name: 'myPoint',
  data () {
    return {
      point: 0,
      isShowDeadLine: false, // 是否显示积分有效期
      pointDeadLine: `${currentYear}-12-31 23:59:59`,
      activeTab: 'redeem',
      activeTabTitle: '积分兑换',
      tabs: [
        {
          key: 'redeem',
          name: '积分兑换'
        },
        {
          key: 'pointDetail',
          name: '积分明细'
        },
        {
          key: 'pointRule',
          name: '积分规则'
        }
      ]
    }
  },
  created () {
    this.getPersonCredit()
    this.getCreditDisplayClear()
  },
  mounted () {
    this.isFixed = false
  },
  methods: {
    // 选中的tab切换时
    handleTabChange (name, title) {
      this.activeTabTitle = title
    },
    // tabs吸顶或者不吸顶时重新渲染tabs组件 使下划线能够在正确的位置
    handleScroll ({ isFixed }) {
      // isFixed改变时才触发
      if (this.isFixed !== isFixed) {
        this.isFixed = isFixed
        this.$refs.tabs.resize()
      }
    },
    // 积分清空是否显示
    async getCreditDisplayClear () {
      let res = await getCreditDisplayClear()
      if (res.success) {
        this.isShowDeadLine = res.data
      }
    },
    // 获取个人积分
    async getPersonCredit () {
      let loading = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true
      })

      let res = await getPersonCredit()

      loading.clear()

      if (res.success) {
        this.point = res.data.creditAmount
      } else {
        this.$toast(res.message)
      }
    }
  },
  components: {
    'redeem': () => import('./redeem.vue'),
    'pointDetail': () => import('./pointDetail.vue'),
    'pointRule': () => import('./pointRule.vue')
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .content-wrapper{
    padding: 0 24px;
    .point-header-wrapper{
      @include bgImage('../../assets/point_bg', 702px, 352px);
      color: #fff;
      padding: 27px;
      margin-top: 24px;
      margin-bottom: 24px;
      box-sizing: border-box;
      .title-wrapper{
        @include flexCenter(space-between);
        .title{
          text-indent: 264px;
        }
        .leaderboard{
          font-size: 24px;
          font-weight: 500;
          text-align: center;
          p{
            margin-top: 5px;
          }
        }
        .iconpaihangbang{
          font-size: 38px;
        }
      }
      .point{
        text-align: center;
        font-size: 140px;
        margin-top: 24px;
        font-weight: bold;
      }
      .deadline{
        font-size: 24px;
        text-align: center;
        opacity: .5;
      }
    }
    .tab-list{
      // margin-top: 24px;
      margin-bottom: 24px;
      box-shadow:0px 4px 10px 0px rgba(221,221,221,0.5);
    }
    .tab-content-wrapper{
      display: flex;
      flex-direction: column;
      padding: 0px;
      background: #fff;
      border-radius: 8px;
      .title{
        padding: 24px;
        font-size: 32px;
        color: #333;
        font-weight: 600;
      }
    }
  }
</style>
